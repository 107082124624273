import { newsMediaOrgSchema } from "./seo-schemas/news-media-org-schema";
import { collectionPageSchema } from "./seo-schemas/collection-page-schema";
import { webSiteSchema } from "./seo-schemas/website-schema";

/**
 * Generates page metadata
 * @param {string} keywords
 * @param {string} url
 * @param {string} description
 * @param {string} title
 * @param {string} ogImage
 * @param {string} contentType
 * @returns {React.ReactElement[]}
 */
const getHomepageMetadata = (
  keywords,
  url,
  description,
  title,
  ogImage,
  contentType,
  isHomepage
) => {
  const metaKeyPrefix = `${contentType}-meta`;

  return [
    // Standard
    <meta
      httpEquiv="X-UA-Compatible"
      content="IE=edge,chrome=1"
      key={`${metaKeyPrefix}-httpequiv`}
    />,
    <meta charSet="utf-8" key={`${metaKeyPrefix}-charset`} />,
    <meta
      id="viewport"
      name="viewport"
      content="width=device-width, initial-scale=1.0, user-scalable=yes, minimum-scale=0.5, maximum-scale=2.0"
      key={`${metaKeyPrefix}-viewport`}
    />,
    <meta
      name="description"
      content={description}
      key={`${metaKeyPrefix}-description`}
    />,
    <meta
      name="referrer"
      content="unsafe-url"
      key={`${metaKeyPrefix}-referrer`}
    />,
    <meta
      name="keywords"
      content={keywords}
      key={`${metaKeyPrefix}-keywords`}
    />,
    <meta
      name="news_keywords"
      content={keywords}
      key={`${metaKeyPrefix}-news_keywords`}
    />,
    <meta
      name="twitter:site"
      value="@WashingtonPost"
      key={`${metaKeyPrefix}-twitter:site`}
    />,
    <meta
      name="twitter:card"
      content="summary_large_image"
      key={`${metaKeyPrefix}-twitter:card`}
    />,
    <meta property="og:type" content="" key={`${metaKeyPrefix}-og:type`} />,
    <meta
      property="og:site_name"
      content="Washington Post"
      key={`${metaKeyPrefix}-og:site_name`}
    />,
    <meta name="magnet" content="" key={`${metaKeyPrefix}-magnet`} />,
    <meta
      property="fb:app_id"
      content="41245586762"
      key={`${metaKeyPrefix}-fb:app_id`}
    />,
    <meta
      property="fb:admins"
      content="4403963"
      key={`${metaKeyPrefix}-fb:admins`}
    />,
    <meta
      property="fb:admins"
      content="500835072"
      key={`${metaKeyPrefix}-fb:admins_2`}
    />,
    // SEO Schemas
    isHomepage && (
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(webSiteSchema) }}
        key={`${contentType}-website-script-schema`}
      />
    ),
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(newsMediaOrgSchema) }}
      key={`${contentType}-newsorg-script-schema`}
    />,
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(collectionPageSchema(url, title, description))
      }}
      key={`${contentType}-collection-script-schema`}
    />,
    // More
    <meta name="external" content="true" key={`${metaKeyPrefix}-external`} />,
    // Title
    <title key={`${contentType}-title`}>{title}</title>,
    // eslint-disable-next-line react/no-unknown-property
    <link property="og:url" href={url} key={`${contentType}-link-og:url`} />,
    <meta
      property="og:image"
      content={ogImage}
      itemProp="image"
      key={`${metaKeyPrefix}-og:image`}
    />,
    <meta
      property="og:title"
      content={title}
      key={`${metaKeyPrefix}-og:title`}
    />,
    <meta
      property="og:description"
      content={description}
      key={`${metaKeyPrefix}-og:description`}
    />,
    <meta
      name="robots"
      content="index,follow"
      key={`${metaKeyPrefix}-robots`}
    />,
    <meta name="theme" content="normal" key={`${metaKeyPrefix}-theme`} />,
    <meta name="audio_url" content="" key={`${metaKeyPrefix}-audio_url`} />,
    <meta
      name="google-site-verification"
      content="6Bi3yUCN2g3lzvqapLfrbgkQxob5YCjmZidGa2qiM4g"
      key={`${metaKeyPrefix}-google-site-verification`}
    />
  ];
};

export default getHomepageMetadata;
